import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    downloadReport(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/institution-report/advanced-export', { params: queryParams, responseType: 'blob' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPhysicians(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/physician/all-by-institution', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchReport(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/institution-report/advanced', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchReportAccountability(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/institution-report/accountability', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchReportAccountabilityExport(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/institution-report/accountability-export', { params: queryParams, responseType: 'blob' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    exportPaymentVoucher(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/contract/export-payment-voucher/${queryParams.contractId}`, { responseType: 'blob' })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWorkplaces(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/workplace', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
